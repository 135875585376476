import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When I was young we lived in a place at the very tip of North Africa. A place called Tunisia. Tunisia was filled with kind and caring people. They want to help you. They want to dance. They want to smile. They want the best for everyone. This is a lie. A lie devised to cover up the Tunisian equal to the Global Drug-Trade market. An underground market consisting of evil and hate, all devised on something so beautiful and innocent.`}<br parentName="p"></br>{`
`}{`The violin.`}</p>
    <p>{`It started simply, as a harmless hobby. I signed up for the class thinking nothing of it; what a fool I was. When I walked into that big bright room, it felt cheerful. Filled with children, most of whom would fall to the evil surrounding the beautiful instrument. But then, we were oblivious. I looked up at the teacher with my naive eyes. So innocent. I held the instrument to my chin. And that’s when it began.`}</p>
    <p>{`“Stop messing with the violin, you’ll break it!” said the teacher. He looked at me. I thought nothing of it and placed the violin on my lap. He looked at me. “Not on your lap! Hold it like you can see everyone else doing.” I looked around myself. All I could see were the 40 or so children in the room, and all of their eyes looking straight back at me. I shivered.`}</p>
    <p>{`I should’ve quit right there. I was stupid, a fool. I fell right into their petty trap, like a mouse to a mouse trap. There was no escape. I was stuck. Chained down by my light, bright, violin. The next day, we were in a smaller portable classroom. Painted with white walls, and filled with no more than 10 chairs. That was where I would meet my fellow flies, trapped in the streaming, endless web. We played as good as we could. But it was never enough. Each and every class the assault was worse.`}</p>
    <p>{`One day, one horrid day. The string of my violin snapped. I still remember the look I got from the teacher. It struck me cold. I stopped moving as he walked towards me. He snached my violin, looked me in the eyes, said nothing and walked away. He didn’t give it back until 2 weeks later. When I got it back, I realized he had down-graded my violin to a smaller one. And given mine to a new child sitting on the other side of the room.`}</p>
    <p>{`I played bad that day. Real bad. “Are you trying to make us all sound bad?” the teacher would say. “Is this another one of your pranks?”. There was nothing I could do. I couldn’t re-learn how to play on a smaller violin. I stood. Faced this lion of a man. And told him I wanted my old violin back.`}</p>
    <p>{`“Get out,” He said.`}</p>
    <p>{`“What?”`}</p>
    <p>{`“Get out!” His face grew red, and he shook in anger, “GET OUT, GET OUT, GET OUT GET OUT GET OUT GET OUT GET OUT GET OUT!”`}</p>
    <p>{`I grabbed my stuff and left.`}</p>
    <p>{`“AND DONT YOU EVEN THINK ABOUT COMING BACK.”`}</p>
    <p>{`A couple weeks went by and I was re-invited to play violin, but now my teacher was different. She was the fattest, most heavily obease woman I have ever seen. She filled the room, not just with her personality. It was nice to see a new teacher, I thought maybe we’d work well together. I was so wrong. So very wrong.`}</p>
    <p>{`She was even worse than her predecessor. The first day she handed us sheet music and expected us to play it immediately, without practice. When we failed to do so she would ask us to leave the room and stand outside.`}</p>
    <p>{`This abuse went on and on. The torment grew and grew and my hatred for the instrument grew with it. Every day a new problem, once she stole someone’s sandwich because they didn’t play quietly enough. I remember her leaving the classroom for over an hour, only to come back with a full meal.`}</p>
    <p>{`There was one incident, so serious, I never returned to that classroom. I would never set foot near a violin again. Something that startled me so much I would throw away my passion for good.`}</p>
    <p>{`It happened on a Friday, that day had been going well. I had practiced my music (as if I didn’t she would ask you to practice outside the classroom in the heat for hours till she thought you were good enough). When I walked inside something felt… off. Something was wrong. She didn’t seem normal. Like a lion that hadn’t been fed. More dangerous than usual. More aggressive. We made eye-contact. She didn’t say hello, as was the norm. We sat and she said her usual greeting, which was saying nothing. She stood before us and began to count:`}</p>
    <p>{`“1, 2, 3, 4” and we began to play. I was entranced, focused, completely in the zone. We got through the intro, we separated for the chorus, 2 groups playing in harmony.`}</p>
    <p>{`“STOP” she walked up and down. Eyeing each of us down. She stood in front of a quivering child, and asked him to get his stuff and leave. After he was gone she stood in front of us.`}</p>
    <p>{`“Play” We began again, from the beginning. The intro was perfect. The chorus came and went beautifully, and the first verse began.`}</p>
    <p>{`“SHUT UP, Everybody Stop. What is this?” She looked everyone in the eye, and grabbed a musician by the arm and made him leave, this time not even allowing him to grab his stuff.`}</p>
    <p>{`This time there was no queue, she just moved her hand in rhythm and we started. Each movement was perfect, flawless. My playing was the best it’s ever been.`}</p>
    <p>{`“STOP, STOP, SHUT UP, ALL OF YOU!!!”`}</p>
    <p>{`The loud, booming voice of my violin teacher thundered down as a lion would, ready for attack. The lion pounced, all 500 pounds of her. Brutally wobbling over to me. Her fat wobbled as a penguin would walk. Like a seal’s flab, being dragged across the room. She stood in front of me, glaring down. Her breath was sickening. Like the smell of a skunk, or that of a rotting egg. She bent down, I felt her dark, gloomy shadow. My fingers trembled over my bright, wooden violin. With fingers the size of an arm, she reached out. She was poised and ready to strike. She ripped the violin right out of my hands. I froze like a deer in headlights. Every single eye in the room was on me.`}</p>
    <p>{`“What are you doing? Have you listened to me at all?” She took a step closer. Took in a deep breath to support her next insults. “You are out of tune! Stop DESTROYING the entire ensemble’s sound, with your out of tune violin you little-!” She held herself back. She began to brutally assault my instrument for the only purpose of tuning it. In truth, I wasn’t out of tune, she had heard my friend, who had forgotten to tune that day, I had tuned before the lesson. I didn’t speak out as I was scared of her reaction.`}</p>
    <p>{`Then my face grew red, and a salty, warm, but yet so cold, tear grew from my eye. I tried to hold it back. Evade the inevitable by blinking it out. There was no avoiding it. It was too late.`}</p>
    <p>{`“Why are you crying? Have I hurt your feelings?” She looked at me, acting as if she was concerned, but the same thing would repeat itself day after day. Night after night, week after week, and month after month. She handed me back my violin and told me to wipe the tear off my face and focus. I reached out for the violin, and she thrust it into my hand. Almost knocking me over. She told me to play. I took a breath, paused, and moved my shakey hands across the strings. The sound the instrument screamed was very similar to nails chalkboard, a cat screaming, goats dying. She picked up my sheet music and threw it at me.`}</p>
    <p>{`“Is that all you can do? Read the marking there! Read the marking!” I looked up at her, the marking read piano, the musical term for reasonably quiet. I stood, with regained confidence.`}</p>
    <p>{`“PLAY AGAIN, maybe this time it’ll be good!”. My hands trembled across the strings, “THATS NOT PIANO” she screamed, I wanted to disappear. She stood there, looking at me. I had to play. But her reaction was inevitable. “NO. No, no, no, no, NO! P-I-A-N-O!” She enunciated every letter of the word. “PLAY PIANO! PLAY IT NOW.” She grabbed the sheet music off the ground and ripped it in half. She threw it on the floor again, and stomped on it. She looked at me, in the eyes, like a murderer before the final blow, and finally she sat back down. It was too late, though, the damage was done.`}</p>
    <p>{`Finally, I had to put an end to this madness, I walked out of that forboden, ugly, violin studio, and never went back. Throwing that part of me away for good.`}</p>
    <hr></hr>
    <p>{`“The violin quivers like a tormented heart”. When in hand, it sobs, it smiles, it darkens, it brightens. The bow strokes in, the bow strokes out. Like peace, like war. Like brotherhood, like betrayal.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      